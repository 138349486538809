import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'gatsby'

const validationSchema = Yup.object().shape({
  customername: Yup.string()
    .min(2, 'Customer name must be 2 characters at minimum')
    .required('Name is required'),
  customeremail: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  address: Yup.string()
    .min(2, 'Address must be 2 characters minimum')
    .required('Address is required'),
  city: Yup.string()
    .min(2, 'City must be 2 characters minimum')
    .required('City is required'),  
  state: Yup.string()
    .min(2, 'State must be 2 characters minimum')
    .required('State is required'),   
  zip: Yup.string()
    .min(2, 'Zip must be 2 characters minimum')
    .required('Zip is required'),                
  country: Yup.string()
    .min(2, 'Country must be 2 characters minimum')
    .required('Country is required'),       
  comments: Yup.string()
    .min(10, 'Description must be 10 characters at minimum')
    .required('Issue/problem is required'),    
})

const formUrl = 'https://script.google.com/macros/s/AKfycbz_VPBuA-CyifOZIjVuZuB87L8NAnyNuWAWbOrKpwHcc9FJzjr23PjIO6NQF5I3nNEX/exec'

const messageConfirm = () => {
  return toast.info("Thank you for contacting us.  We'll be in touch shortly!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class formSupportInformation extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null,
      purchaseDate: new Date, 
      load: false,
      expired: 'false',
    }
  }

  getPurchaseDate = value => {
    this.setState({
      purchaseDate: value
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true,
        pageUrl: location.href 
      })
    })
  }

  handleChange = (value) => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol lg="9" md="12" className="md-0 mb-5">
            <Formik
              initialValues={{
                customername: '',
                customeremail: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                phone: '',
                comments: '',
                subscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  CustomerName: values.customername,
                  CustomerEmail: values.customeremail,
                  Address: values.address,
                  City: values.city,
                  State: values.state,
                  Zip: values.zip,
                  Country: values.country,
                  Phone: values.phone,
                  Comments: values.comments,
                  Subscribe: values.subscribe,
                }
                try {
                  const response = axios({
                    method: 'get',
                    url: `${formUrl}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&name=${encodeURIComponent(values.customername)}&email=${encodeURIComponent(values.customeremail)}&street=${encodeURIComponent(values.address)}&city=${encodeURIComponent(values.city)}&state=${encodeURIComponent(values.state)}&zip=${encodeURIComponent(values.zip)}&country=${encodeURIComponent(values.country)}&phone=${encodeURIComponent(values.phone)}&comments=${encodeURIComponent(values.comments)}&subscribe=${encodeURIComponent(values.subscribe)}`,
                  })
                  //console.log(response)
                  this.setState({ values: '', expired: 'true' })
                  resetForm()
                  messageConfirm()
                } catch (e) {
                  console.log(`Axios request failed: ${e}`)
                }
                setSubmitting(false)
              }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form name="contact" method="post" action="" data-netlify="true" data-netlify-honeypot="bot-field" >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />

                  <section className="form-section rounded">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">General information</h2>
                          <hr />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="6">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                         <label htmlFor="customername">Customer name <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            id="customername"
                            name="customername"
                            aria-label="customername"
                            value={values.customername}
                            placeholder="Your name"
                            className={`form-control ${ touched.customername && errors.customername ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="customername"
                            className="invalid-feedback"
                          />
                        </div>
                      </MDBCol>

                      <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                         <label htmlFor="customeremail">Customer email <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            id="customeremail"
                            name="customeremail"
                            aria-label="customeremail"
                            value={values.customeremail}
                            placeholder="Your email address"
                            className={`form-control ${ touched.customeremail && errors.customeremail ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="customeremail"
                            className="invalid-feedback"
                          />
                        </div>
                      </MDBCol>                      
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="address">Address <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="textarea"
                            component="textarea"
                            name="address"
                            value={values.address}
                            placeholder="Enter your address"
                            className={`form-control ${ touched.address && errors.address ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="address"
                            className="invalid-feedback"
                          />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="4">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="city">City <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            name="city"
                            value={values.city}
                            placeholder="Your city of residence"
                            className={`form-control ${ touched.city && errors.city ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="city"
                            className="invalid-feedback"
                          />
                        </div>
                      </MDBCol>

                      <MDBCol md="4">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="state">State/province <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            name="state"
                            value={values.state}
                            placeholder="Your state of residence"
                            className={`form-control ${ touched.state && errors.state ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="state"
                            className="invalid-feedback"
                          />
                        </div>
                      </MDBCol>

                      <MDBCol md="4">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="zip">Zip/postcode <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            name="zip"
                            value={values.zip}
                            placeholder="Your zip/postcode of residence"
                            className={`form-control ${ touched.zip && errors.zip ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="zip"
                            className="invalid-feedback"
                          />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="6">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="country">Country <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            name="country"
                            value={values.country}
                            placeholder="Your country of residence"
                            className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="country"
                            className="invalid-feedback"
                          />
                        </div>
                      </MDBCol>

                      <MDBCol md="6">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="phone">Phone <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            name="phone"
                            value={values.phone}
                            placeholder="Your phone number"
                            className={`form-control ${ touched.phone && errors.phone ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="phone"
                            className="invalid-feedback"
                          />
                        </div>
                      </MDBCol>                      
                    </MDBRow>
                  </section>

                  <section className="form-section rounded">
                  <MDBRow>
                        <MDBCol md="12">
                          <div className="mb-4 grey-text font-w-600 text-medium">
                            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">Description</h2>
                            <hr />
                          </div>
                        </MDBCol>
                    </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="comments">
                          Issue/problem <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="textarea"
                          component="textarea"
                          name="comments"
                          value={values.comments}
                          placeholder="Please let us know the problem you are having"
                          className={`form-control ${ touched.comments && errors.comments ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="comments"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  </section>                  

                  <hr /> 

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        To receive occasional updates about USR's
                        products, services and news, please tick the box
                        below. You can unsubscribe at any time.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="subscribe"
                          name="subscribe"
                          aria-label="subscribe"
                          className={`form-check-input ${ touched.subscribe && errors.subscribe ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="subscribe">Yes, I would like to receive communications from USR.</label>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        For details on how your data is stored and used, please visit our{' '} <Link to="/privacy-statement/" className="effect"> Privacy Statement </Link> .
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={isSubmitting ? true : false}
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />                        
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default formSupportInformation