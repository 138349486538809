import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'gatsby'

const validationSchema = Yup.object().shape({
  customername: Yup.string()
    .min(2, 'Customer name must be 2 characters at minimum')
    .required('Name is required'),
  customeremail: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),  
  country: Yup.string()
    .min(2, 'Country must be 2 characters minimum')
    .required('Country is required'), 
  productcode: Yup.string().required('Product is required'),
  serialnumber: Yup.string().required('Serial number is required'),
  operatingsystem: Yup.string().required('OS is required'),
  comments: Yup.string()
    .min(10, 'Description must be 10 characters at minimum')
    .required('Issue/problem is required'),    
})

const formUrl = 'https://script.google.com/macros/s/AKfycbxIOSHdZkxNXQqUr4lDMwjwcFfS5alLoHiPqPPZBlJb0TCKMky63f3U9067KYA5hzEQ1g/exec'

const messageConfirm = () => {
  return toast.info("Thank you for contacting us.  We'll be in touch shortly!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class formSupportInformation extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null,
      purchaseDate: new Date, 
      load: false,
      expired: 'false',
    }
  }

  getPurchaseDate = value => {
    this.setState({
      purchaseDate: value
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true,
        pageUrl: location.href 
      })
    })
  }

  handleChange = (value) => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol lg="9" md="12" className="md-0 mb-5">
            <Formik
              initialValues={{
                customername: '',
                customeremail: '',
                country: '',
                phone: '',
                productcode: '',
                serialnumber: '',
                operatingsystem: '',
                comments: '',
                subscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  CustomerName: values.customername,
                  CustomerEmail: values.customeremail,
                  Country: values.country,
                  Phone: values.phone,
                  ProductCode: values.productcode,
                  SerialNumber: values.serialnumber,
                  OperatingSystem: values.operatingsystem,
                  Comments: values.comments,
                  Subscribe: values.subscribe,
                }
                try {
                  const response = axios({
                    method: 'get',
                    url: `${formUrl}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&name=${encodeURIComponent(values.customername)}&email=${encodeURIComponent(values.customeremail)}&country=${encodeURIComponent(values.country)}&phone=${encodeURIComponent(values.phone)}&productcode=${encodeURIComponent(values.productcode)}&serialnumber=${encodeURIComponent(values.serialnumber)}&operatingsystem=${encodeURIComponent(values.operatingsystem)}&comments=${encodeURIComponent(values.comments)}&subscribe=${encodeURIComponent(values.subscribe)}`,
                  })
                  //console.log(response)
                  this.setState({ values: '', expired: 'true' })
                  resetForm()
                  messageConfirm()
                } catch (e) {
                  console.log(`Axios request failed: ${e}`)
                }
                setSubmitting(false)
              }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form name="contact" method="post" action="" data-netlify="true" data-netlify-honeypot="bot-field" >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />

                  <section className="form-section rounded">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">General information</h2>
                          <hr />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="6">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                         <label htmlFor="customername">Customer name <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            id="customername"
                            name="customername"
                            aria-label="customername"
                            value={values.customername}
                            placeholder="Your name"
                            className={`form-control ${ touched.customername && errors.customername ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="customername" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                         <label htmlFor="customeremail">Customer email <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            id="customeremail"
                            name="customeremail"
                            aria-label="customeremail"
                            value={values.customeremail}
                            placeholder="Your email address"
                            className={`form-control ${ touched.customeremail && errors.customeremail ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="customeremail" className="invalid-feedback" />
                        </div>
                      </MDBCol>                      
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="6">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="country">Country <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            id="country"
                            name="country"
                            aria-label="country"
                            value={values.country}
                            placeholder="Your country of residence"
                            className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="country" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol md="6">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="phone">Phone</label>
                          <Field
                            type="phone"
                            name="phone"
                            value={values.phone}
                            placeholder="Your phone number"
                            className={`form-control ${ touched.phone && errors.phone ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="phone" className="invalid-feedback" />
                        </div>
                      </MDBCol>                      
                    </MDBRow>
                  </section>

                  <section className="form-section rounded">
                    <MDBRow>
                        <MDBCol md="12">
                          <div className="mb-4 grey-text font-w-600 text-medium">
                            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">Product information</h2>
                            <hr />
                          </div>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="productcode">USR product code <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="productcode"
                            name="productcode"
                            id="productcode"
                            as="select"
                            value={values.productcode}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.productcode && errors.productcode ? 'is-invalid' : '' }`}
                          >
                            <option value="" label="Please select a product"></option>
                            <option value="USR013453C" label="USR013453C"> USR013453C </option>										       
                            <option value="USR065637" label="USR065637"> USR065637 </option>               
                            <option value="USR265670" label="USR265670"> USR265670 </option>               
                            <option value="USR2980-OEM" label="USR2980-OEM"> USR2980-OEM </option>               
                            <option value="USR325610C" label="USR325610C"> USR325610C </option>               
                            <option value="USR3453C" label="USR3453C"> USR3453C </option>
                            <option value="USR3453C-REF" label="USR3453C-REF">USR3453C-REF</option>
                            <option value="USR3500" label="USR3500"> USR3500 </option>               
                            <option value="USR3510" label="USR3510"> USR3510 </option>               
                            <option value="USR3513" label="USR3513"> USR3513 </option>               
                            <option value="USR3516-EMU" label="USR3516-EMU"> USR3516-EMU </option>               
                            <option value="USR3520" label="USR3520"> USR3520 </option>               
                            <option value="USR4000" label="USR4000"> USR4000 </option>               
                            <option value="USR4005" label="USR4005"> USR4005 </option>               
                            <option value="USR4009" label="USR4009"> USR4009 </option>               
                            <option value="USR4204" label="USR4204"> USR4204 </option>               
                            <option value="USR4503" label="USR4503"> USR4503 </option>               
                            <option value="USR4504" label="USR4504"> USR4504 </option>               
                            <option value="USR4515LC" label="USR4515LC"> USR4515LC </option>               
                            <option value="USR4516LC" label="USR4516LC"> USR4516LC </option>               
                            <option value="USR4523" label="USR4523"> USR4523 </option>               
                            <option value="USR4524" label="USR4524"> USR4524 </option>               
                            <option value="USR4524-MINI" label="USR4524-MINI"> USR4524-MINI </option>               
                            <option value="USR4525" label="USR4525"> USR4525 </option>               
                            <option value="USR4526" label="USR4526"> USR4526 </option>               
                            <option value="USR5610C" label="USR5610C"> USR5610C </option>               
                            <option value="USR5637" label="USR5637"> USR5637 </option>               
                            <option value="USR5638" label="USR5638"> USR5638 </option>               
                            <option value="USR5639" label="USR5639"> USR5639 </option>               
                            <option value="USR5670" label="USR5670"> USR5670 </option>               
                            <option value="USR5686G" label="USR5686G"> USR5686G </option>               
                            <option value="USR5686G-PRO" label="USR5686G-PRO"> USR5686G-PRO </option>               
                            <option value="USR803510" label="USR803510"> USR803510 </option>               
                            <option value="USR803513" label="USR803513"> USR803513 </option>               
                            <option value="USR805637" label="USR805637"> USR805637 </option>
                            <option value="USR805671" label="USR805671">USR805671</option>               
                            <option value="USR805670" label="USR805670">USR805670</option>
                            <option value="USR843453C" label="USR843453C">USR843453C</option>                                                    
                          </Field>
                          <ErrorMessage component="div" name="productcode" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                         <label htmlFor="serialnumber">Serial number <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            id="serialnumber"
                            name="serialnumber"
                            aria-label="serialnumber"
                            value={values.serialnumber}
                            placeholder="Product serial number"
                            className={`form-control ${ touched.serialnumber && errors.serialnumber ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="serialnumber" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="operatingsystem">Operating system <span style={{ color: 'red' }}>*</span></label>
                            <Field
                              type="text"
                              id="operatingsystem"
                              name="operatingsystem"
                              aria-label="operatingsystem"
                              value={values.operatingsystem}
                              placeholder="Enter your OS"
                              className={`form-control ${ touched.operatingsystem && errors.operatingsystem ? 'is-invalid' : '' }`}
                            />
                            <ErrorMessage component="div" name="operatingsystem" className="invalid-feedback" />
                          </div>
                        </MDBCol>
                    </MDBRow>
                  </section>


                  <section className="form-section rounded">
                  <MDBRow>
                        <MDBCol md="12">
                          <div className="mb-4 grey-text font-w-600 text-medium">
                            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">Description</h2>
                            <hr />
                          </div>
                        </MDBCol>
                    </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="comments">
                          Issue/problem <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="textarea"
                          component="textarea"
                          name="comments"
                          value={values.comments}
                          placeholder="Please let us know the problem you are having"
                          className={`form-control ${ touched.comments && errors.comments ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage component="div" name="comments" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  </section>                  

                  <hr /> 

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        To receive occasional updates about USR's
                        products, services and news, please tick the box
                        below. You can unsubscribe at any time.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="subscribe"
                          name="subscribe"
                          aria-label="subscribe"
                          className={`form-check-input ${ touched.subscribe && errors.subscribe ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="subscribe">Yes, I would like to receive communications from USR.</label>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        For details on how your data is stored and used, please visit our{' '} <Link to="/privacy-statement/" className="effect"> Privacy Statement </Link> .
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={isSubmitting ? true : false}
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />                        
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default formSupportInformation